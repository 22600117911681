<template>
    <div class="page">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm">
            <el-form-item prop="title">
                <el-input size="small" v-model.trim="searchForm.title" maxlength="20" placeholder="请输入藏品名称或编号"
                          clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="select()" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white top">
            <el-row>
                <el-col :span="18">
                    <AdvancedQuery ref="advancedQuery" :moduleId="moduleId" @getDataList="getDataList"></AdvancedQuery>
                </el-col>
                <el-col :span="6" class="text_right">
                    <el-button-group class="m_r1">
                        <el-button size="small" type="primary" :disabled="dataListSelections.length <= 0"
                                   @click="issue(0)">一键发布
                        </el-button>
                        <el-button size="small" plain icon="el-icon-refresh-left" @click="refreshAll"></el-button>
                        <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
                    </el-button-group>
                </el-col>
            </el-row>
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small"
                    height="calc(100% - 80px)"
                    @selection-change="selectionChangeHandle"
                    class="table" ref="multipleTable">
                <el-table-column
                        type="selection"
                        width="50" fixed>
                </el-table-column>
                <el-table-column prop="thumb_path" width="110" label="缩略图">
                    <template slot-scope="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.thumb_path" fit="contain">
                            <el-image slot="error" fit="contain" style="width: 100px; height: 100px"
                                      :src="require('@/assets/img/thumbnail.png')"></el-image>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column v-for="(item,index) in columnList" :key="index"
                                 v-if="item.fieldEname != 'thumb_path'"
                                 :prop="item.fieldEname" width="160"
                                 show-overflow-tooltip sortable
                                 :label="item.fieldName">
                    <template slot-scope="scope">
                        <span v-if="item.fieldEname!='thumb_path'">{{scope.row[item.fieldEname]}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="relationResourceInfo"
                        show-overflow-tooltip sortable
                        label="关联资源">
                </el-table-column>
                <el-table-column fixed="right" width="150" label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" plain v-show="hasPermission('collectionDatabase:details:view')"
                                   @click="view('view', scope.row)">查看</el-button>
                        <el-button size="mini" type="primary" v-show="hasPermission('collectionDatabase:releaseList:release')"
                                   plain @click="issue(1, scope.row.id)">发布</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--列表设置拖拽排序弹窗-->
        <DraggablePop :moduleId="moduleId" :setShow="setShow" @getTbList="getTbList"></DraggablePop>
        <!--查看-->
        <PublishedForm ref="publishedForm" @refreshDataList="refreshList"></PublishedForm>
        <!--发布-->
        <el-dialog title="藏品发布" width="60%" :visible.sync="visible" :before-close="handleClose"
                   :close-on-click-modal="false">
            <el-form size="small" :model="inputForm" ref="inputForm" label-width="120px" :rules="rules5">
                <el-form-item label="发布栏目：" prop="topicId">
                    <!--:rules="[{ required: true, message: '请选择发布栏目' }]"-->
                    <el-col :span="16">
                        <el-select v-model="inputForm.topicId" style="width:100%" placeholder="请选择发布栏目">
                            <el-option
                                    v-for="item in columnOpt"
                                    :key="item.id"
                                    :label="item.topicName"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-col>
                </el-form-item>
                <el-form-item label="是否审核：" prop="onReview">
                    <el-switch v-model="inputForm.onReview" :active-value=0 :inactive-value=1></el-switch>
                </el-form-item>
                <el-form-item label="是否显示：" prop="onShow">
                    <el-switch v-model="inputForm.onShow" :disabled="inputForm.onReview==1" :active-value=0
                               :inactive-value=1></el-switch>
                </el-form-item>
                <el-form-item label="排序号：" prop="resourceSort" v-if="sortShow">
                    <el-input-number max="9999" v-model="inputForm.resourceSort" :min="1"></el-input-number>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">取消</el-button>
                <el-button size="small" type="primary" v-show="hasPermission('collectionDatabase:releaseList:release')"
                           @click="collectionIssue()" v-noMoreClick>发布</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import DraggablePop from '@/components/draggable/draggablePop2' // 导入排序弹窗
    import AdvancedQuery from '@/components/advancedQuery/advancedQuery' // 导入高级筛选弹窗
    import PublishedForm from './publishedForm' // 查看弹窗
    export default {
        components: {DraggablePop, AdvancedQuery, PublishedForm},
        data() {
            return {
                searchForm: {
                    title: "",
                },
                dataList: [],
                /*originalId: '',*/
                pageNo: 0,
                pageSize: 10,
                total: 0,
                loading: false,
                dataListSelections: [],
                columnList: [],
                onScene: false, // 是否正在使用场景
                dataListQuery: [], // 临时储存场景数据
                moduleId: '946049935122366464',   // 当前请求拖拽排序数据id
                setShow: false, // 是否显示列表设置拖拽排序弹窗
                // 藏品发布
                visible: false,
                sortShow: true, // 一键发布不显示排序号
                issueType: 1,
                issueIds: [],
                inputForm: {
                    topicId: '',
                    /*isShow: 0,
                    isAudit: 0,
                    sequence: 0,*/
                    onShow: 0,
                    onReview: 0,
                    resourceSort: '',
                    onPublish: 0,

                },
                columnOpt: [
                    {
                        id: '1',
                        name: '何绍基展外借文物',
                    }
                ],
                rules5: {
                    topicId: [
                        {required: true, message: '请选择发布栏目', trigger: 'change'}
                    ]
                },
            }
        },
        mounted() {
            this.getTableByResourceName()
            this.getPubTopicList()
        },
        methods: {
            handleClose() {
                this.visible = false;
                this.$refs.inputForm.resetFields();
            },
            // 获取藏品资源ID
            getTableByResourceName() {
                this.$axios(this.api.dyyg.metaDataDirectoryList, {
                    resourceName: '藏品资源',
                }, 'get').then(res => {
                    if (res.status) {
                        this.moduleId = res.data.records[0].id;
                    }
                })
            },
            // 栏目列表
            getPubTopicList() {
                this.$axios(this.api.dyyg.pubTopicList, {}, 'get').then(res => {
                    if (res.status) {
                        this.columnOpt = res.data.records
                    }
                })
            },
            select() {
                this.pageNo = 0
                this.refreshList()
            },
            // 刷新按钮回到全部列表
            refreshAll() {
                this.onScene = false
                this.dataListQuery.queryConditions = []
                this.searchForm.title = ''
                this.pageNo = 0
                this.refreshList()
            },
            // 获取数据列表
            refreshList() {
                if (this.onScene || (this.dataListQuery.queryConditions != null && this.dataListQuery.queryConditions.length > 0)) {
                    this.getDataList(this.dataListQuery)
                    return
                }
                this.dataListQuery.sceneId = ''
                this.loading = true
                this.$axios(this.api.dyyg.pushCollectionList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    'type': this.searchForm.type,
                    'searchNumOrName': this.searchForm.title,
                    'originalId': this.moduleId
                }, 'get').then(data => {
                    this.dataList = data.data.records
                    this.total = parseInt(data.data.total);
                    this.visible = false
                    this.loading = false
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout();
                    })
                })
            },
            // 获取显示字段
            getColumnList() {
                var that = this;
                this.columnList = []
                that.$axios(this.api.dyyg.getByOriginalId + this.moduleId, '', "get").then((res) => {
                    if (res.status) {
                        res.data.map(item => {
                            if (item.logicType == 0) {
                                this.columnList.push(item)
                            }
                        })
                    }
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 查看
            view(method, row) {
                this.$refs.publishedForm.init(row);
            },

            // 发布、一键发布
            issue(type, id) {
                this.issueType = type
                if (type == 1) {
                    this.sortShow = true
                    this.issueIds = []
                    this.issueIds.push(id)
                } else {
                    this.sortShow = false
                    this.issueIds = this.dataListSelections.map(item => {
                        return item.id
                    })
                    this.$set(this.inputForm, 'resourceSort', 9999)
                    this.$set(this.inputForm, 'onAllPush', true)

                    // 一键发布用于将下斜杆转为驼峰命名
                    this.dataListSelections.map(item => {
                        this.$set(item, 'collectionLevel', item.collection_level)
                        this.$set(item, 'collectionTexture', item.collection_texture)
                    })
                    this.$set(this.inputForm, 'collectionDTOS', this.dataListSelections)
                }
                this.visible = true
            },
            // 确定藏品发布
            collectionIssue() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.$set(this.inputForm, 'originalId', this.moduleId)
                        this.$set(this.inputForm, 'collectionIds', this.issueIds)

                        this.$confirm(`确定发布吗?`, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.$axios(this.api.dyyg.pushCollectionSave, this.inputForm, 'post').then(data => {
                                if (data && data.status) {
                                    this.$message.success(data.msg)
                                    this.inputForm.resourceSort = 1
                                    //this.visible = false
                                    this.refreshList()
                                    this.handleClose();
                                }
                            })
                        })
                    }
                })

            },
            // 查询
            getFileList() {
                this.loading = true
                this.$axios(this.api.zlzs.culturalRelicsList, {}, 'get').then(data => {
                    if (data && data.status) {
                    }
                })
            },
            // 获取高级查询条件
            getDataList(data) {
                this.loading = true
                if (data != null && data.sceneId != null && data.sceneId != '') {
                    this.onScene = true
                } else {
                    this.onScene = false
                }
                if (data.clearCurrent != null && data.clearCurrent == true) {
                    this.pageNo = 0
                }
                console.log("1", data.clearCurrent)
                this.dataListQuery = data
                this.searchTpye = 0
                data.size = this.pageSize
                data.current = this.pageNo
                data.pageId = this.moduleId
                data.collectionPageType = 1
                data.searchNumOrName = this.searchForm.title

                this.$axios(this.api.dyyg.listReleaseQueryCollection, JSON.stringify(data), 'post').then(res => {
                    if (res && res.status) {
                        this.dataList = res.data.records
                        this.total = parseInt(res.data.total);
                    }
                })
                this.loading = false
            },
            // 设置
            setTb() {
                this.setShow = true;
            },
            // 接收子组件（排序弹窗）传过来的值
            getTbList(data, state) {
                this.columnList = data;
                this.setShow = state;
                this.refreshList()
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 0
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.refreshList()
                }
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.refreshList()
                }
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            resetSearch() {
                this.dataListQuery = []
                this.$refs.advancedQuery.addForm.sceneId = ''
                this.$refs.searchForm.resetFields()
                this.$nextTick(() => {
                    this.onScene = false
                    this.pageNo = 0
                    this.refreshList()
                })
            }
        }
    }
</script>
